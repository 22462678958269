<script>
import {defineComponent} from 'vue'
import CustomTable from "@/components/tables/table.vue";
import OrderService from "@/api";
import DocPreviewModal from "@/views/pages/smgs/modal/DocPreview.vue";
import Multiselect from "@vueform/multiselect";
import 'vue-select/dist/vue-select.css';
import "@vueform/multiselect/themes/default.css";

export default defineComponent({
  name: "Documents",
components: {DocPreviewModal, CustomTable, Multiselect},
  data() {
    return {
      table_url: '/smgs/document/',
      table_headers: [
        {
          label: 'ID',
          field: 'id',
          align: 'center',
          visible: false
        },
        {
          label: 'DOCUMENT',
          field: 'name',
          align: 'center',
        },
        {
          label: 'PDF FILE',
          field: 'path',
          align: 'center',
          searchable: false,
        },
        {
          label: 'PAGES',
          field: 'pages',
          align: 'center',
          searchable: false,
        },
        {
          label: 'SYNC CODE',
          field: 'sync_code',
          align: 'center',
          searchable: false,
        },
        {
          label: 'TRAIN',
          field: 'train_id',
          align: 'center',
          searchable: false,
        },
        {
          label: 'actions',
          field: 'actions',
        },
      ],

      showUploadDocModal: false,
      addTrainDocumentModal: false,
      selected_doc_id: null,
      is_reading_doc: false,
      time_left: 0,
      file_url: '',
      train: {},
      order_service_url: process.env.VUE_APP_ORDER_URL + '/',
    }
  },
  methods: {
    async uploadSMGSPDF() {
      if (this.is_reading_doc) {
        return
      }
      const formData = new FormData();

      formData.append('file', document.getElementById('pdffile').files[0]);
      this.is_reading_doc = true
      OrderService({
        url: '/smgs/pdf/',
        method: 'post',
        data: formData,
        headers: {"Content-Type": "multipart/form-data"},
      }).then(async (res) => {
        const document_id = res.data.id
        if (this.train.selected && this.train.selected != 0) {
          await this.addTrainDocument(document_id)
        }
        await this.processDocument(document_id)
      }).catch((err) => {
        return err
      })
    },
    async showTrainDocumentModal(document_id) {
      this.selected_doc_id = document_id
      this.addTrainDocumentModal = true
    },
    async addTrainDocument(document_id) {
      if (!document_id) {
        document_id = this.selected_doc_id
      }
      OrderService({
          url: '/smgs/add-train-document/',
          method: 'patch',
          data: {
            train_id: this.train.selected.value, 
            document_id: document_id,
          },
        }).catch((err) => {
          return err
      })
      this.addTrainDocumentModal = false
    },
    async getTrain(query) {
      if (query.length <= 3) return;
      await OrderService({url: `/train/list/?name=${query}`})
          .then(res => {
            let options = res.data.results
            if (options.length > 0) {
              options = options.map((el) => {
                return {value: el.id, label: el.name}
              })
            }
              this.train.selected = null
              this.train.options = options
          }).catch((err) => {
            console.log(err)
          })
    },
    async processDocument(document_id) {
      setInterval(() => {
        this.time_left += 1
      }, 1000);
      await OrderService({
        method: 'post',
        url: `smgs/process/${document_id}/`,
      })

      await this.$router.push({name: 'smgs_documents_update', params: {id: document_id}})
      this.showUploadDocModal = false
      this.is_reading_doc = false
    },
  }
})
</script>

<template>

  <b-modal v-model="showUploadDocModal" hide-footer title="Upload SMGS PDF" class="v-modal-custom" centered>
    <form action="/" method="POST" @submit.prevent="uploadSMGSPDF">
      <b-card v-if="is_reading_doc">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h6 class="mb-0"><b class="text-success">{{ time_left * 3 }}%</b> Update in progress...</h6>
          </div>
          <div class="flex-shrink-0">
            <h6 class="mb-0">{{ 30 - time_left }}s left</h6>
          </div>
        </div>
        <b-progress :value="time_left*3" class="custom-progress bg-soft-success rounded-0"
                    variant="success"></b-progress>
      </b-card>
      <div class="modal-body">
        <div class="mb-3">
          <label for="ConnectTrain" class="form-label">Train</label>
          <Multiselect
              v-model="train.selected"
              :searchable="true"
              :closeOnSelect="true"
              :options="train.options"
              placeholder="please, select train"
              @search-change="getTrain($event)"
              :object="true"
          />
        </div>
        <div class="mb-3">
          <label for="uploadSmgsDocument" class="form-label">Train</label>
          <input id="pdffile" class="form-control" type="file"
                 accept="application/pdf,application/vnd.ms-excel"/>
        </div>
        </div>  
      <div class="modal-footer hstack gap-2 justify-content-end">
        <b-button type="button" variant="light" @click="showUploadDocModal = false">
          Close
        </b-button>
        <b-button type="submit" variant="success">Submit</b-button>
      </div>
    </form>
  </b-modal>
  <b-modal v-model="addTrainDocumentModal" hide-footer title="Upload SMGS PDF" class="v-modal-custom" centered>
    <form action="/" method="PATCH" @submit.prevent="addTrainDocument(0)">
      <div class="modal-body">
        <div class="mb-3">
          <label for="ConnectTrain" class="form-label">Train</label>
          <Multiselect
              v-model="train.selected"
              :searchable="true"
              :closeOnSelect="true"
              :options="train.options"
              placeholder="please, select train"
              @search-change="getTrain($event)"
              :object="true"
          />
        </div>
      </div>  
      <div class="modal-footer hstack gap-2 justify-content-end">
        <b-button type="button" variant="light" @click="addTrainDocumentModal = false">
          Close
        </b-button>
        <b-button type="submit" variant="success">Submit</b-button>
      </div>
    </form>
  </b-modal>

  <DocPreviewModal :file_url="file_url"/>

  <CustomTable
      :url="table_url"
      name="SMGS Documents"
      id="doc_table"
      :headers="table_headers"
      :searchable="true"
      :selectable="true"

  >
    <template v-slot:top-right>
      <div class="btn-group">
        <b-button class="p-2 m-1" variant="primary"
                  @click="showUploadDocModal = !showUploadDocModal">SMGS PDF
        </b-button>
      </div>
    </template>

    <template v-slot:name="slotProps">
      <h5 class="fw-medium link-primary my-0">
                                <span class="badge badge-soft-primary">
                                  {{ slotProps.row.name.slice(slotProps.row.name.indexOf('_') + 1) }}
                                </span>
      </h5>
    </template>
    <template v-slot:path="slotProps">
      <span v-if="slotProps.row.path === null" class="text-danger">-</span>
      <span v-if="slotProps.row.path !== null"
            data-bs-toggle="modal"
            data-bs-target="#doc_preview_modal"
            @click="file_url= order_service_url+slotProps.row.path" class="text-danger">
                                  <font-awesome-icon
                                      icon="fa-solid fa-file-pdf"
                                      class="c_icon_hoverable text-secondary"
                                  />
                            </span>
    </template>
    <template v-slot:pages="{row: smgs}">
              <span class="badge"
                    :class="{
                        'bg-warning': smgs.completed_smgses !== smgs.sync_code,
                        'bg-info': smgs.completed_smgses < smgs.pages,
                        'bg-success':  smgs.completed_smgses === smgs.sync_code,
                        'bg-danger': smgs.completed_smgses === 0 || smgs.completed_smgses !== smgs.sync_code,
                    }">
                {{ smgs.completed_smgses + '/' + smgs.pages }}
              </span>
    </template>
    <template v-slot:sync_code="{row: smgs}">
              <span class="badge"
                    :class="{
                         'bg-danger': smgs.sync_code === 0 || smgs.completed_smgses !== smgs.sync_code,
                         'bg-success': smgs.completed_smgses === smgs.sync_code,
                         'bg-info': smgs.sync_code < smgs.pages,
                    }">
                {{ smgs.sync_code }}
              </span>
    </template>

    <template v-slot:actions="slotProps">
      <div class="w-100 text-center">
        <font-awesome-icon
                           @click="showTrainDocumentModal(slotProps.row.id)"
                           icon="fa-solid fa-plus"
                           class="c_icon  me-3  c_icon_hoverable"/>
        <font-awesome-icon v-if="slotProps.row.pages===0"
                           @click="processDocument(slotProps.row.id)"
                           icon="fa-solid fa-arrow-up-right-from-square"
                           class="c_icon  me-3  c_icon_hoverable"/>
        <router-link :to="{name: 'smgs_documents_update', params: {id: slotProps.row.id}}">
          <font-awesome-icon
              icon="fa-solid fa-pen-to-square"
              class="c_icon me-3 c_icon_hoverable text-dark"
          />
        </router-link>
        <!-- <font-awesome-icon @click="deleteDocRow(slotProps.row)" icon="fa-solid fa-trash"
                           class="c_icon c_icon_hoverable text-danger"
        /> -->
      </div>
    </template>
  </CustomTable>
</template>

<style scoped>

</style>